import { genericRequest } from "../generic";
import { urlTasksLoad, urlTasksMarkAsComplete, urlTasksSetStatus } from "../config";

/**
 *  # apiTasksLoadGroup
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiTasksLoadGroup = async (groupName, authorization) => {
    return genericRequest(urlTasksLoad + `/${groupName}`, "get", {}, authorization);
};

export const apiTasksCreate = async (payload, authorization) => {
    return genericRequest(urlTasksLoad, "post", payload, authorization);
};

export const apiTasksMarkAsComplete = async (payload, authorization) => {
    return genericRequest(urlTasksMarkAsComplete, "post", payload, authorization);
};

export const apiTasksSetStatus = async (payload, authorization) => {
    return genericRequest(urlTasksSetStatus, "post", payload, authorization);
};

