
// The base url.
// export const baseUrl =  `https://api.${window.location.hostname}`; // "http://192.168.1.12"; // "http://127.0.0.1";

export const baseUrl =  `https://api.radiolaria.io`; // `http://localhost:5010`;
export const verson = "1.0.0"

/*
    Tasks related urls.
*/
export const urlTasksLoad = `${baseUrl}/v1/tasks`;
export const urlTasksMarkAsComplete = `${baseUrl}/v1/task/complete`;
export const urlTasksSetStatus = `${baseUrl}/v1/task/status`;


/*
    Groups related urls.
*/
export const urlGroupsLoad = `${baseUrl}/v1/groups`;
export const urlGroupsListPermissions = `${baseUrl}/v1/groups/iam`;
export const urlGroupSetPermission = `${baseUrl}/v1/permissions`;


/*
    Requirements related urls.
*/
export const urlRequirementsLoad = `${baseUrl}/v1/requirements`;
export const urlRequirementsMarkComplete = `${baseUrl}/v1/requirements/set-complete`;
export const urlAuthLogin = `${baseUrl}/v1/authorization/user/login`;
export const urlAuthRegister = `${baseUrl}/v1/authorization/user/create`;

/*
    Resource related urls.
*/
export const urlResourceLoad = `${baseUrl}/v1/resources`;
export const urlResourceCreate = `${baseUrl}/v1/resources`;
