import * as React from 'react';

import { apiTasksCreate } from '../../api/tasksAPI';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const sxSetting = {
    height: 100,
    /*
     width: 300,
    height: 300,
    backgroundColor: 'primary.dark',
    '&:hover': {
        backgroundColor: 'primary.main',
        opacity: [0.9, 0.8, 0.7],
    },*/
}

function unwrap(arg){
    return arg.target.value;
}

function handleInjectingValue(i, requirements, setRequirements, fieldName, value, setUpdated){
    let tmp = requirements;
    tmp[i][fieldName] = value;

    setRequirements(tmp);
    setUpdated(Math.random());
}

function removeItem(i, requirements, setRequirements, setUpdated){
    let tmp = requirements;

    tmp.splice(i, 1);

    setRequirements(tmp);
    setUpdated(Math.random());
}

function buildRequirementSection(i, requirements, setRequirements, setUpdated){
    // console.log("current", current)
    const current = requirements[i];

    return (
        <div>
            <Divider />
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate>
                <div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        defaultValue=""
                        value={current.title}
                        onChange={(arg) => {
                            handleInjectingValue(i, requirements, setRequirements, "name", unwrap(arg), setUpdated)
                        }}
                    />
                    <TextField
                        id="outlined-required"
                        label="Description"
                        value={current.description}
                        onChange={(arg) => {
                            handleInjectingValue(i, requirements, setRequirements, "description", unwrap(arg), setUpdated)
                        }}
                    />
                    <TextField
                        id="outlined-required"
                        label="Link"
                        defaultValue=""
                        value={current.title}
                        onChange={(arg) => {
                            handleInjectingValue(i, requirements, setRequirements, "link", unwrap(arg), setUpdated)
                        }}
                    />
                    <NativeSelect
                        defaultValue={30}
                        value={current.unit}
                        onChange={(arg) => {
                            handleInjectingValue(i, requirements, setRequirements, "unit", unwrap(arg), setUpdated)
                        }}>
                        <option key="" value=""></option>
                        <option key="item" value="item">Item</option>
                        <option key="LB" value="LB">LB</option>
                        <option key="KG" value="KG">KG</option>
                    </NativeSelect>
                    <TextField
                        required
                        id="outlined-required"
                        label="Count"
                        value={current.count}
                        onChange={(arg) => {
                            handleInjectingValue(i, requirements, setRequirements, "count", Number(unwrap(arg)), setUpdated)
                        }}
                    />
                    <Button variant="contained" onClick={()=>{ removeItem(i, requirements, setRequirements, setUpdated)}}>Remove</Button>
                </div>
            </Box>
        </div>
    )
}

export default function FormPropsTextFields(props) {

    const [group, setGroup] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [link, setLink] = React.useState('');
    const [points, setPoints] = React.useState(1);
    const [timeToComplete, setTimeToComplete] = React.useState(0);
    const [priority, setPriority] = React.useState(1);
    const [notes, setNotes] = React.useState('');
    const [updated, setUpdated] = React.useState(1);
    const [requirements, setRequirements] = React.useState([]);
    const [keywords, setKeywords] = React.useState([]);

    const onFinish = (values) => {
        console.log(values);

        let payload = {
            "task": {
                "user_id":          "user_id",
                "title":            title,
                "description":      description,
                "groups":           group,
                "link":             link,
                "points":           Number(points),
                "time_to_complete": Number(timeToComplete),
                "priority":         Number(priority),
                "notes":            notes,
                "keywords":         keywords.length === 0 ? [] : String(keywords).split(","),
                "status":          "to_do"
            },
            "requirements": requirements
        };

        apiTasksCreate(payload, props.jwt).then((arg)=>{
            console.log("arg", arg)

            if(arg.success === false){
                return;
            }

            window.location.reload();
        });
    };



    const { groups } = props;

    let optionList = [
        <option key="" value=""></option>
    ];
    let keys = Object.keys(groups);

    for(let i = 0; i < keys.length; i += 1){
        optionList.push(
            <option key={keys[i]} value={keys[i]}>{groups[keys[i]].name}</option>
        );
    }

    let requirementBuilt = [];

    for(let i = 0; i < requirements.length; i += 1){
        requirementBuilt.push(buildRequirementSection(i, requirements, setRequirements, setUpdated))
    }

    return (
        <React.Fragment>
             <Box sx={sxSetting} className={updated}>
                <FormControl sx={{ minWidth: 240 }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">Group</InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                        onChange={(arg) => setGroup(unwrap(arg))}
                    >
                    {optionList}
                    </NativeSelect>
                </FormControl>
             </Box>
             <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Title"
                        defaultValue=""
                        onChange={(arg) => setTitle(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Description"
                        defaultValue=""
                        onChange={(arg) => setDescription(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Link"
                        defaultValue=""
                        onChange={(arg) => setLink(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-number"
                        label="Points"
                        type="number"
                        defaultValue={1}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(arg) => setPoints(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-number"
                        label="Time To Complete (Min)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(arg) => setTimeToComplete(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-number"
                        label="Priority"
                        type="number"
                        defaultValue={1}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(arg) => setPriority(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Notes"
                        defaultValue=""
                        onChange={(arg) => setNotes(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Keywords (separated by ,)"
                        defaultValue=""
                        value={keywords}
                        onChange={(arg) => setKeywords(unwrap(arg))}
                    />
                </div>
            </Box>
            <Box>
                <Button variant="contained" onClick={()=>{

                    let tmp = requirements;

                    tmp.push({
                        "name": "",
                        "description": "",
                        "link": "",
                        "unit": "",
                        "count": 0,
                    });

                    setRequirements(tmp);
                    setUpdated(updated + 1);
                }}>Add Requirement</Button>
            </Box>
            <Box>
                {requirementBuilt}
            </Box>
            <Box sx={{ height: 100 }}>
                <Button
                    variant="contained"
                    onClick={onFinish}
                >Submit</Button>
            </Box>
        </React.Fragment>
    );
}
