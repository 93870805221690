import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StartIcon from '@mui/icons-material/Start';

import { apiTasksSetStatus } from '../../api/tasksAPI';

const priorityMap = {
  1: "Low",
  2: "Medium",
  3: "High"
};

const widthSettings = {
  "default": 170,

  "link": 80,
  "points": 80,
  "priority": 100,
  "title": 250,
  "description": 350,
  "status": 100,
  "controls": 130,
}

const statusMap = {
  "to_do": "To do",
  "active": "In-progress"
};

const deleteClick = (id, jwt) => {
  if (window.confirm("Are you sure?")) {
    const resourceType = "todo";
    // If it's requirements type.
    if (resourceType === "requirements") {
      // markAsComplete(row);
    }else{
      apiTasksSetStatus({"id": id, "status": "deleted"}, jwt)
        .then(()=>{ window.location.reload(); })
    }
  }
}

const updateStatusClick = (id, updateStatus, jwt, noRefresh) => {
  apiTasksSetStatus({
    "id": id,
    "status": updateStatus
  }, jwt).then(()=>{
    if(!noRefresh) {
      window.location.reload();
    }
  })
}

function generateControl(argument, jwt){

  let actionButtons = [];

  // Start or update the button.
  let updateStatus = argument.row.status === "In-progress"
      ? "complete" : "active";

  let iconValue = argument.row.status === "In-progress"
      ? <CheckBoxIcon /> : <PlayCircleFilledWhiteIcon />;

  if(argument.row.status !== "In-progress") {
    actionButtons.push(
      <IconButton onClick={() => { updateStatusClick(argument.row.id, "complete", jwt, true); }}>
          <StartIcon />
      </IconButton>
    );
  }

  actionButtons.push(
    <IconButton onClick={() => { updateStatusClick(argument.row.id, updateStatus, jwt, false); }}>
        {iconValue}
    </IconButton>
  );

  // Delete button.
  actionButtons.push(
    <IconButton onClick={() => { deleteClick(argument.row.id, jwt, false); }} aria-label="delete">
      <DeleteIcon />
    </IconButton>
  );

  return actionButtons;
}

function generateHeader(argument, jwt) {
  let list = [];

  for (let i = 0; i < argument.length; i += 1) {
    const width = widthSettings[argument[i].id] !== undefined
      ? widthSettings[argument[i].id] : widthSettings["default"];


    if (argument[i].id === "link") {
      list.push({
        field: argument[i].id,
        headerName: argument[i].label,
        width: width,
        renderCell: (params) => {
          return (<a href={params.value}>Link</a>)
        }
      });
      continue;
    }

    if (argument[i].id === "controls") {
      list.push({
        field: argument[i].id,
        headerName: argument[i].label,
        width: width,
        renderCell: (params) => {
          return generateControl(params, jwt)
        }
      });
      continue;
    }

    
    list.push({
      field: argument[i].id,
      headerName: argument[i].label,
      width: width
    });
  }

  return list;
};

function generateRows(argument) {
  let list = [];

  for (let i = 0; i < argument.length; i += 1) {
    list.push({
      id: argument[i].id,
      title: argument[i].title,
      description: argument[i].description,
      points: argument[i].points,
      priority: priorityMap[argument[i].priority],
      link: argument[i].link,
      time_to_complete: argument[i].time_to_complete,
      status: statusMap[argument[i].status],
    },);
  }

  return list;
};

export default function DataTable(props) {
  // processed, markAsComplete
  const { headCells, rows, title, jwt } = props;

  return (
    <div style={{ height: 400, width: '100%' }}>
      <h2>{title}</h2>
      <DataGrid
        rows={generateRows(rows)}
        columns={generateHeader(headCells, jwt)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        checkboxSelection
      />
    </div>
  );
}