import { genericRequest } from "../generic";
import { urlRequirementsLoad, urlRequirementsMarkComplete } from "../config";

/**
 *  # apiTasksLoadGroup
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiRequirementsLoad = async (payload, authorization) => {
    return genericRequest(urlRequirementsLoad, "post", payload, authorization);
};

/**
 *  # apiRequirementsComplete
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiRequirementsComplete = async (payload, authorization) => {
    return genericRequest(urlRequirementsMarkComplete, "post", payload, authorization);
};

