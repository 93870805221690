import types from "../constants";

// set constants
let initialState = {
   lastUpdate: 0,
   resources: [],
};

/**
 * # userMadeReducer
 */
const resourcesReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.RESOURCES_SET:
            state.lastUpdate = Math.random();
            state.resources = payload["payload"];

            return { ...state };

        default:
            return state;
    };
};

export default resourcesReducer;