import React from 'react';
import { connect } from "react-redux";
import { Redirect }    from "react-router-dom";

// Components.
import Loading           from '../../component/Loading';
import TaskTable         from '../../embeddable/TasksTable';
import RequirementsTable from '../../embeddable/RequirementsTable';

// Redux and API requests.
import { tasksSetGroup }       from '../../redux/actions/tasks';
import { requirementsSet }     from '../../redux/actions/requirements';
import { apiTasksLoadGroup }   from "../../api/tasksAPI";
import { apiRequirementsLoad } from '../../api/requirementsAPI';



class Tasks extends React.Component {

    constructor(arg){
        super(arg);

        this.state = {
            id: "",
            data: [],
            redirect: "",
        };
    }
    /**
     * # load
     * Load the tasks for the group.
     */
    load = async (groupId) => {
        const tasks = await apiTasksLoadGroup(groupId, this.props.jwt);

        if(tasks.success === false){
            this.setState({ id: groupId });
            this.props.tasksSetGroup({ "group": groupId, "payload": [] })
            return;
        }

        let activeTasks = [];

        // Find all of the active tasks.
        for(let i = 0; i < tasks.result.length; i += 1){
            if(tasks.result[i].status === "active"){
                activeTasks.push(tasks.result[i].id);
            }
        }

        // Load all of the requirements.
        const requirements = await apiRequirementsLoad({ "ids": activeTasks }, this.props.jwt);
        if(requirements.success){
            this.props.requirementsSet({ "group": groupId, "payload": requirements.result });
        }

        this.setState({ id: groupId });
        this.props.tasksSetGroup({ "group": groupId, "payload": tasks.result })
    };
    /**
     * # componentDidMount
     */
    componentDidMount(){
        this.load(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]);
    };
    /**
     * # render
     */
    render(){

        if(this.state.id === ""){
            return <Loading text="loading" />;
        }

        // So we can redirect the user to their lobby.
        let forceRedirect = null;

        if(this.state.redirect.length > 0){
            forceRedirect = <Redirect to={this.state.redirect} />;
        }

        // Alias out the group.
        let groupAlias = this.props.groups[this.state.id];

        if(groupAlias !== undefined){
            groupAlias = groupAlias.name;
        }else{
            groupAlias = this.state.id;
        }

        // The list of tasks.
        let taskList = [];

        if(this.props.list[this.state.id] !== undefined){
            taskList = this.props.list[this.state.id];
        }

        let requirementsList = {};

        if(this.props.requirementList[this.state.id] !== undefined){
            requirementsList = this.props.requirementList[this.state.id];
        }

        /*
            <div className="create-button">
                <Button size="large" type="primary" onClick={()=>{ this.setState({ redirect: "/tasks/create" })}}>
                    Create Task
                </Button>
            </div>
        */
           
        return (
            <React.Fragment>
                <div className="c-tasks">
                    <div key={this.props.requirementListLU + "a"} className="table">
                        <RequirementsTable jwt={this.props.jwt} rows={requirementsList} title={groupAlias} />
                    </div>
                    <div key={this.props.listLU} className="table">
                        <TaskTable rows={taskList} title={groupAlias} jwt={this.props.jwt} />
                    </div>
                </div>
                {forceRedirect}
            </React.Fragment>
        );
    };
}

const myStateToProps = (state) => {
    return {
        list:              state.taskReducer.tasks,
        listLU:            state.taskReducer.lastUpdate,
        requirementList:   state.requirementsReducer.requirements,
        requirementListLU: state.requirementsReducer.lastUpdate,
        groups:            state.groupReducer.groups
    };
};

export default connect(myStateToProps, { tasksSetGroup, requirementsSet })(Tasks);