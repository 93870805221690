// import logo from './logo.svg';
import React from 'react';
import './App.css';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 

import { groupsAddGroup } from "./redux/actions/groups";
import { resourcesSet } from "./redux/actions/resources";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Containers
import Tasks       from './containers/Tasks';
import TaskCreate  from './containers/TaskCreate';
import { GroupEdit, GroupCreate, GroupList }      from './containers/Groups';
import Homepage    from './containers/Homepage';
import Login       from './containers/Login';
import Register    from './containers/Register';
import Resources   from './containers/Resources';
import ResourceCreate from './containers/ResourceCreate';
// import Error from './containers/Error';

// Components
import Loading from './component/Loading';
import Navbar from './component/Navbar';
import Footer from './component/Footer';

// import GTable from './generic/GTable';

// import Tasks from './component/Tasks';
// import TaskCreate from './component/TaskCreate';
// import Ingesters from './containers/Ingesters';

// Api requests.
import { apiGroupLoadGroup } from './api/groupsAPI';
import { apiResourceLoad } from './api/resourcesAPI';


class App extends React.Component {

  state = {
    loaded: false,
  };
  /**
   * # handleChange
   * Handle the user input.
   * 
   * @param {string} type - The name of the input.
   * 
   * @returns none
   */
  handleChange = (type) => {
      return (e) => {
      this.setState({
          [type]: e.target.value
      })
      }
  };
  /**
   * # loadGroups
   * Load the groups and inject them into our state.
   */
  loadGroups = () => {
    let context = this;

    apiGroupLoadGroup(this.props.jwt).then((arg)=>{
      if(arg.success === false){
        alert("error loading groups");
        return;
      }

      if (arg.result.groups === null) {
        arg.result.groups = [];
      }

      // Save the data.
      context.props.groupsAddGroup(arg.result);

      context.setState({ loaded: true });
    })
  };
  loadResources = () => {
    let context = this;

    apiResourceLoad(this.props.jwt).then((arg)=>{
      if(arg.success === false){
        alert("error loading resources");
        return;
      }

      if (arg.result === null) {
        arg.result = [];
      }

      // Save the data.
      context.props.resourcesSet(arg.result);
      // context.setState({ loaded: true });
    })
  }
  /**
   * 
   */
  load = (arg) => {
      // let context = this;

      // Automatically load the groups for the user.

      /*
      apiTasksLoadGroup(arg, "auth").then((arg)=>{

          if(arg.success === false){
              alert("error loading");
              return;
          }

          context.setState({
              "data": arg.result
          });

          tasksSetGroup({
              "group": arg,
              "payload": arg.result,
          })
      })
      */
  };
  /**
   * # componentDidMount
   */
  componentDidMount(){
    // Others loads.

    // Load the groups.
    if(this.props.jwt !== ""){
      this.loadGroups();
      this.loadResources();
    }
  };
  /**
   * # render
   */
  render(){

    let content = (
      <div>
        <Loading text="loading" />
      </div>
    )

    // Temp if it's a direct link to a group with public configuration.
    const windowLocation = window.location.href;

    console.log("windowLocation", windowLocation)

    // If it's a direct link to a group
    if(windowLocation.indexOf("public=true") >= 0 && windowLocation.indexOf("tasks/list") >= 0) {
      return <Tasks jwt="ANY_USER" />
    }

    // If not logged in force login.
    if(this.props.jwt === "" || window.location.pathname === "/login" || window.location.pathname === "/register") {

      if (window.location.pathname === "/register") {
        return (
          <div>
            <Register />
          </div>
        )
      } else {
        return (
          <div>
            <Login />
          </div>
        )
      }
    }

    if(this.state.loaded){
      content = (
        <Switch>
          <Route path="/" exact>
            <Homepage />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/resources" exact>
            <Resources resources={this.props.resources} groups={this.props.groups} counts={this.props.counts} jwt={this.props.jwt} />
          </Route>
          <Route path="/resources/create" exact>
            <ResourceCreate groups={this.props.groups} counts={this.props.counts} jwt={this.props.jwt} />
          </Route>
          <Route path="/group/edit/:id" exact>
            <GroupEdit groups={this.props.groups} counts={this.props.counts} jwt={this.props.jwt} />
          </Route>
          <Route path="/groups" exact>
            <GroupList groups={this.props.groups} counts={this.props.counts} jwt={this.props.jwt} />
          </Route>
          <Route path="/tasks/list/:id" exact>
            <Tasks jwt={this.props.jwt} />
          </Route>
          <Route path="/group/create" exact>
            <GroupCreate groups={this.props.groups} counts={this.props.counts} jwt={this.props.jwt} />
          </Route>
          <Route path="/tasks/create" exact>
            <TaskCreate groups={this.props.groups} jwt={this.props.jwt} />
          </Route>
          <Route path='*'>
            <div>
              Error
            </div>
          </Route>
        </Switch>
      )
    }
    
    return (
      <Router>
        <div className="c-App">
          <div className="App">
            <div className="c-nav-bar">
              <Navbar />
            </div>
            <div className="content">
              {content}
            </div>
            <div className="c-footer">
              <Footer />
            </div>
          </div>
        </div>
      </Router>
    );
  };
}

const myStateToProps = (state) => {
  return {
    groups: state.groupReducer.groups,
    resources: state.resourcesReducer.resources,
    counts: state.groupReducer.counts,
    jwt: state.authReducer.jwt,
  };
};

export default connect(myStateToProps, { groupsAddGroup, resourcesSet })(App);
