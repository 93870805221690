import * as React from 'react';

import { apiGroupsListPerimissions, apiGroupSetPerimissions, apiGroupDeleteGroup } from "../../api/groupsAPI";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function booleanToString(arg) {
    if(arg) {
        return "True";
    } else {
        return "False";
    }
};

function generateBooleanButton(label, booleanValue, callback) {
    return (<Button
        color={booleanValue ? "success" : "error"}
        variant="contained"
        onClick={callback}>{label} {booleanValue ? "allowed" : "not allowed"}</Button>)
};

export default class Tasks extends React.Component {

    constructor(arg){
        super(arg);

        this.state = {
            id: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],
            groupName: "",

            editing: "",
            username: "",
            read: false,
            write: false,
            delete: false,

            loadingPermissions: true,
            permissions: [],
        };
    }

    componentDidMount() {
        this.loadPermissions();

        if (this.props.groups[this.state.id] !== undefined) {
            this.setState({ groupName: this.props.groups[this.state.id].name });
        }
    };

    loadPermissions = () => {
        const { jwt } = this.props;
        apiGroupsListPerimissions(this.state.id, jwt).then(r => {
            if (r.success === false) {
                return;
            }

            if (r.result === null) {
                this.setState({ loadingPermissions: false })
            } else {
                this.setState({ loadingPermissions: false, permissions: r.result })
            }
        })
    };

    renderPermissions = () => {
        return (
            <div>
                <Button sx={{ mt: 2, mb: 2 }} variant="contained"
                    onClick={() => { this.setEditing("new")}}>Create</Button>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>Read</TableCell>
                                <TableCell>Write</TableCell>
                                <TableCell>Delete</TableCell>
                                <TableCell>Approved</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.permissions.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.account_id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {booleanToString(row.read)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {booleanToString(row.write)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {booleanToString(row.delete)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {booleanToString(row.approved)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Button
                                            sx={{ mt: 1, mb: 1 }}
                                            variant="contained"
                                            onClick={()=>{ this.setEditing(row.id)}}
                                            >Edit</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    };

    setEditing = (arg) => {
        const permissionMap = {};

        for(let i = 0; i < this.state.permissions.length; i += 1) {
            permissionMap[this.state.permissions[i].id] = this.state.permissions[i];
        }

        if (arg === "new") {
            this.setState({
                editing: "new",
                username: "",
                read: true,
                write: false,
                delete: false,
            });
            return
        }

        const selected = permissionMap[arg];

        this.setState({
            editing: selected.id,
            username: selected.account_id,
            read: selected.read,
            write: selected.write,
            delete: selected.delete,
        });
    };

    handleReset = () => {
        this.setState({
            editing: "",
            username: "",
            read: false,
            write: false,
            delete: false,
        });
    };

    handleGroupDelete = () => {
        const { jwt } = this.props;
        if (window.confirm("Are you sure?")) {
            apiGroupDeleteGroup({ "ids": [this.state.id]}, jwt).then((result) => {
                window.location.reload();
            });
        }
    }

    renderModel = () => {
        const { jwt } = this.props;

        const handleRead = () => {
            if (this.state.read) {
                this.setState({ read: false, write: false, delete: false });
            } else {
                this.setState({ read: true });
            }
        }

        const handleWrite = () => {
            this.setState({ write: !this.state.write });
        }

        const handleDelete = () => {
            this.setState({ delete: !this.state.delete });
        }

        const handleSubmit = () => {
            const payload = {
                "resource_id": this.state.id,
                "resource_type": "group",
                "account_id": this.state.username,
                "read": this.state.read,
                "write": this.state.write,
                "delete": this.state.delete
            }

            apiGroupSetPerimissions(payload, jwt).then((arg) => {
                if (arg.success === false) {
                    alert("Error setting the permission.")
                }
                window.location.reload();
            })
        }

        let editUsername = null;

        if (this.state.editing === "new") {
            editUsername = (
                <TextField
                    id="filled-basic"
                    label="Username"
                    variant="filled"
                    value={this.state.username}
                    onChange={(arg) => { this.setState({ username: arg.target.value });}}
                />);
        }

        return (
            <Modal
                open={this.state.editing !== ""}
                onClose={this.handleReset}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={8}>
                                    <Typography sx={{ mt: 3, mb: 2 }} component="h1" variant="h5">
                                        <b>Edit Permission for '{this.state.username}'</b>
                                    </Typography>
                                </Grid>
                                {editUsername}
                                <Grid item xs={12} md={12} justifyContent="right">
                                    <Grid container justifyContent="center">
                                        <Grid item xs={4} md={4}>
                                            { generateBooleanButton("Read", this.state.read, handleRead) }
                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            { generateBooleanButton("Write", this.state.write, handleWrite) }
                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            { generateBooleanButton("Delete", this.state.delete, handleDelete) }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={8} justifyContent="right">
                                    <Button sx={{ mt: 2, mb: 2 }} variant="contained" onClick={handleSubmit}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        )
    }

    render(){
        const { groups } = this.props;

        // If the selected group does not exist, reject.
        if (groups[this.state.id] === undefined) {
            return (<div>Unable to load</div>);
        }

        // Render the permission list.
        const permissionMarkup = this.renderPermissions();

        // Render the permission model.
        const permissionModel = this.renderModel();

        return (
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={8} md={8}>
                            <Typography sx={{ mt: 3, mb: 2 }} component="h1" variant="h5">
                                <b>Group: '{this.state.groupName}'</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={2} justifyContent="right">
                            <Button sx={{ mt: 2, mb: 2 }} color="error" variant="contained" onClick={this.handleGroupDelete}>Delete</Button>
                        </Grid>
                    </Grid>
                   {permissionMarkup}
                   {permissionModel}
                </Grid>
            </Grid>
        );
    }
};