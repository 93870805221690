import types from "../constants";

// set constants
let initialState = {
   groups: {},
   counts: {},
};

/**
 * # userMadeReducer
 */
const groupsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.GROUPS_SET_GROUP:

            // Map the fields in.
            for(let i = 0; i < payload["groups"].length; i += 1){
                state.groups[payload["groups"][i]["id"]] = payload["groups"][i];
            }

            state.counts = payload["counts"];

            return { ...state };

        default:
            return state;
    };
};

export default groupsReducer;