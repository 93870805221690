import types from "../constants";

// set constants
let initialState = {
   tasks: {},
   lastUpdate: 0,
};

/**
 * # userMadeReducer
 */
const taskReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.TASK_SET_GROUP:
            state.lastUpdate = Math.random();
            state.tasks[payload["group"]] = payload["payload"];
            return { ...state };

        default:
            return state;
    };
};

export default taskReducer;