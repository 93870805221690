import * as React from 'react';

import { apiResourceCreate } from '../../api/resourcesAPI';

//apiResourceLoad, apiResourceCreate
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';

const sxSetting = {
    height: 100,
    /*
     width: 300,
    height: 300,
    backgroundColor: 'primary.dark',
    '&:hover': {
        backgroundColor: 'primary.main',
        opacity: [0.9, 0.8, 0.7],
    },*/
}

function unwrap(arg){
    return arg.target.value;
}

export default function FormPropsTextFields(props) {

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [link, setLink] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [count, setCount] = React.useState(1);
    const [consumable, setConsumable] = React.useState('true');
    const [unitType, setUnitType] = React.useState('amount');
    const [unit, setUnit] = React.useState('count');

    const onFinish = (values) => {
        console.log(values);

        let payload = {
            "name": name,
            "description": description,
            "link": link,
            "category": category,
            "unitType": unitType,
            "unit": unit,
            "consumable": consumable === "true",
            "count": Number(count)
        };

        apiResourceCreate(payload, props.jwt).then((arg)=>{
            if(arg.success === false){
                return;
            }

            window.location.reload();
        });
    };

        /*
        <Box>
                <Button variant="contained" onClick={()=>{

                

                    // setRequirements(tmp);
                    // setUpdated(updated + 1);
                }}>Add Requirement</Button>
            </Box>
            */

    return (
        <React.Fragment>
             <Box sx={sxSetting}>
                <FormControl sx={{ minWidth: 240 }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">Consumable</InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                        value={consumable}
                        onChange={(arg) => setConsumable(unwrap(arg))}
                    >
                    <option key="true" value="true">True</option>
                    <option key="true" value="false">False</option>
                    </NativeSelect>
                </FormControl>
             </Box>
             <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        value={name}
                        onChange={(arg) => setName(unwrap(arg))}
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Description"
                        value={description}
                        onChange={(arg) => setDescription(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Link"
                        value={link}
                        onChange={(arg) => setLink(unwrap(arg))}
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Category"
                        value={category}
                        onChange={(arg) => setCategory(unwrap(arg))}
                    />
                    <TextField
                        required
                        id="outlined-number"
                        label="Count"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={count}
                        onChange={(arg) => setCount(unwrap(arg))}
                    />
                    <FormControl sx={{ minWidth: 240 }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">Unit Type</InputLabel>
                        <NativeSelect
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                            value={unitType}
                            onChange={(arg) => setUnitType(unwrap(arg))}
                        >
                            <option key="amount" value="amount">Amount</option>
                            <option key="time" value="time">Time</option>
                            <option key="length" value="length">Length</option>
                            <option key="volume" value="volume">Volume</option>
                            <option key="mass" value="mass">Mass</option>
                            <option key="other" value="other">Other</option>
                        </NativeSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 240 }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">Unit</InputLabel>
                        <NativeSelect
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                            value={unit}
                            onChange={(arg) => setUnit(unwrap(arg))}
                        >
                            <option key="count" value="count">Count</option>
                        </NativeSelect>
                    </FormControl>
                </div>
            </Box>
            <Box sx={{ height: 100 }}>
                <Button
                    variant="contained"
                    onClick={onFinish}
                >Submit</Button>
            </Box>
        </React.Fragment>
    );
}
