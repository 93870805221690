import * as React from 'react';
import TableCell from '@mui/material/TableCell';

import GTable from '../../generic/GTable';

// Configuration.
import { requirementConfig, trimStringToLength, injectATag } from '../../api/configuration';
import { apiRequirementsComplete } from '../../api/requirementsAPI';


export default function TaskTable(props) {
    const { rows, jwt } = props;

    let processed = [];

    // Combine the totals based on name.
    let nameKey = {};

    const keys = Object.keys(rows);
    for(let i = 0; i < keys.length; i += 1){
        const current = rows[keys[i]];

        for(let g = 0; g < current.length; g += 1){
            let c = current[g];

            if (c.acquired) {
                continue;
            }

            if(c.name === "Credits"){
                // console.log("c", c.count)
            }

            c.count = Number(c.count);

            // Check if that name already exists.
            if(nameKey[c.name] !== undefined){
                nameKey[c.name].count += current[g].count; // Add it to the total.
                continue;
            }

            // Add the name to it.
            nameKey[c.name] = JSON.parse(JSON.stringify(c));
        }
    }

    // Flatten the rows.
    let flattenRows = [];

    const flatKeys = Object.keys(nameKey);
    for(let i = 0; i < flatKeys.length; i += 1){
        flattenRows.push(nameKey[flatKeys[i]]);
    }

    // Reprocess the rows before injecting them.
    for(let i = 0; i < flattenRows.length; i += 1 ){
        processed.push([
            (<TableCell>{flattenRows[i].name}</TableCell>),
            (<TableCell>{trimStringToLength(flattenRows[i].description, 100)}</TableCell>),
            (<TableCell>{injectATag(flattenRows[i].link)}</TableCell>),
            (<TableCell>{Number(flattenRows[i].count)}</TableCell>),
            (<TableCell>{flattenRows[i].unit}</TableCell>),
        ])
    }

    let markAsComplete = (inputRow) => {
        let ids = [];
        let keys = Object.keys(rows);

        for (let i = 0; i < keys.length; i += 1) {

            let toDoList = rows[keys[i]];

            for (let g = 0; g < toDoList.length; g += 1){
                if (toDoList[g].name !== inputRow.name) {
                    continue;
                }

                ids.push(toDoList[g].id);
            }
        }

        if (ids.length === 0) {
            return;
        }

        apiRequirementsComplete({ "ids": ids }, jwt)
            .then(()=>{ window.location.reload(); });
    }

    

    if(flattenRows.length === 0){
        return null;
    }

    return (
        <GTable
            headCells={requirementConfig.headCells}
            processed={processed}
            rows={flattenRows}
            title={"Requirements"}
            type="requirements"
            markAsComplete={markAsComplete}
            />
    );
    
}