import * as React from 'react';

import { Link }    from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import Box from '@mui/material/Box';

export default function Groups(props) {
    const { groups } = props;

    let list = [];
    let keys = Object.keys(groups);

    for(let i = 0; i < keys.length; i += 1){
        list.push(groups[keys[i]]);
    }

    // item xs={12} sm={8} md={5} component={Paper} elevation={6} square

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={8} md={8}>
                        <Typography sx={{ mt: 3, mb: 2 }} component="h1" variant="h5">
                            <b>Groups</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} md={2} justifyContent="right">
                        <Link to={`/group/create`}>
                            <Button  sx={{ mt: 2, mb: 2 }} variant="contained">Create</Button>
                        </Link>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Total Tasks</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/tasks/list/${row.id}`}>{row.name}</Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {(props.counts[row.id] !== undefined) ? props.counts[row.id]: 0}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/group/edit/${row.id}`}>Edit</Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}