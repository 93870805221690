import * as React from 'react';
import GTable from '../../generic/GTable';

// Configuration.
import { taskConfig } from '../../api/configuration';


// Configure the controls for the table.
export default function TaskTable(props) {
    const { rows, title, jwt } = props;

    return (
        <GTable headCells={taskConfig.headCells} rows={rows} title={title} jwt={jwt} />
    );
}