/*
    Task related configuration.
*/
export const taskConfig = {

    // The label for the priority values.
    convertPriority: {
        1: "Low",
        2: "Medium",
        3: "High"
    },

    convertStatus: {
        "to_do": "To Do",
        "active": "Active",
        "done": "Finished"
    },

    // The table structure.
    headCells: [
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Title',
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        {
            id: 'points',
            numeric: false,
            disablePadding: true,
            label: 'Points',
        },
        {
            id: 'priority',
            numeric: false,
            disablePadding: true,
            label: 'Priority',
        },
        {
            id: 'time_to_complete',
            numeric: false,
            disablePadding: true,
            label: 'Time To Complete',
        },
        {
            id: 'link',
            numeric: false,
            disablePadding: true,
            label: 'Link',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'controls',
            numeric: false,
            disablePadding: true,
            label: 'Controls',
        },
    ],
};

export const requirementConfig = {


     
    // The table structure.
    headCells: [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
        },
        {
            id: 'link',
            numeric: false,
            disablePadding: true,
            label: 'Link',
        },
        {
            id: 'count',
            numeric: false,
            disablePadding: true,
            label: 'Count',
        },
        {
            id: 'unit',
            numeric: false,
            disablePadding: true,
            label: 'Unit',
        },
        {
            id: 'controls',
            numeric: false,
            disablePadding: true,
            label: 'Controls',
        },
    ],
};

export function trimStringToLength(arg, maxSize){
    if(arg.length > maxSize){
        return arg.slice(0, maxSize) + "...";
    }
    
    return arg
};

export function injectATag(arg){
    if(arg.length === 0){
        return "N/A";
    }

    return (<a href={arg}> Link </a>)
};