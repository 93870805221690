import * as React from 'react';

import { apiGroupsCreate } from '../../api/groupsAPI';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

const sxSetting = {
    height: 100,
    /*
     width: 300,
    height: 300,
    backgroundColor: 'primary.dark',
    '&:hover': {
        backgroundColor: 'primary.main',
        opacity: [0.9, 0.8, 0.7],
    },*/
}

function unwrap(arg){
    return arg.target.value;
}

export default function FormPropsTextFields(props) {

    // unwrap
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [type, setType] = React.useState('default');

    const onFinish = (values) => {
        console.log(values);

        let payload = {
            "name": name,
            "description": description,
            "type": type
        };

        apiGroupsCreate(payload, props.jwt).then((arg)=>{
            console.log("arg", arg)

            if(arg.success === false){
                return;
            }

            window.location.reload();
        });
    };

    return (
        <React.Fragment>
             <Box sx={sxSetting}>
                <FormControl sx={{ minWidth: 240 }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">Group</InputLabel>
                    <NativeSelect
                        defaultValue="default"
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                        onChange={(arg) => setType(unwrap(arg))}
                    >
                        <option key="default" value="default">Default</option>
                    </NativeSelect>
                </FormControl>
             </Box>
             <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Name"
                        defaultValue=""
                        onChange={(arg) => setName(unwrap(arg))}
                    />
                    <TextField
                        id="outlined-required"
                        label="Description"
                        defaultValue=""
                        onChange={(arg) => setDescription(unwrap(arg))}
                    />
                </div>
            </Box>
            <Box sx={{ height: 100 }}>
                <Button
                    variant="contained"
                    onClick={onFinish}
                >Submit</Button>
            </Box>
        </React.Fragment>
    );
}
