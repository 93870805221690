import types from "../constants";

// set constants
let initialState = {
   requirements: {},
   lastUpdate: 0,
};

/**
 * # userMadeReducer
 */
const requirementsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.REQUIREMENTS_SET:
            state.lastUpdate = Math.random();
            state.requirements[payload["group"]] = payload["payload"];

            return { ...state };

        default:
            return state;
    };
};

export default requirementsReducer;