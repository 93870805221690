import { genericRequest } from "../generic";
import { urlGroupsLoad, urlGroupsListPermissions, urlGroupSetPermission } from "../config";

/**
 *  # apiTasksLoadGroup
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiGroupLoadGroup = async (authorization) => {
    return genericRequest(urlGroupsLoad, "get", {}, authorization);
};

export const apiGroupsCreate = async (payload, authorization) => {
    return genericRequest(urlGroupsLoad, "post", payload, authorization);
};

export const apiGroupDeleteGroup = async (payload, authorization) => {
    return genericRequest(urlGroupsLoad, "delete", payload, authorization);
};



export const apiGroupsListPerimissions = async (groupId, authorization) => {
    return genericRequest(`${urlGroupsListPermissions}/${groupId}`, "get", {}, authorization);
};

export const apiGroupSetPerimissions = async (payload, authorization) => {
    return genericRequest(urlGroupSetPermission, "post", payload, authorization);
};

