import types from "../constants";

// set constants
let initialState = {
    username: localStorage.getItem("username") === null ? "" : localStorage.getItem("username"),
   jwt: localStorage.getItem("jwt") === null ? "" : localStorage.getItem("jwt"),
   refresh: localStorage.getItem("refresh") === null ? "" : localStorage.getItem("refresh"),
};

/**
 * # userMadeReducer
 */
const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_LOGIN:
            state.username = payload["username"];
            state.jwt = payload["jwt"];
            state.refresh = payload["refresh"];

            localStorage.setItem("username", payload["username"]);
            localStorage.setItem("jwt", payload["jwt"]);
            localStorage.setItem("refresh", payload["refresh"]);

            return { ...state };

        default:
            return state;
    };
};

export default authReducer;