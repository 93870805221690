import { genericRequest } from "../generic";
import { urlResourceCreate, urlResourceLoad } from "../config";

/**
 *  # apiTasksLoadGroup
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiResourceLoad = async (authorization) => {
    return genericRequest(urlResourceLoad, "get", {}, authorization);
};

/**
 *  # apiRequirementsComplete
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiResourceCreate = async (payload, authorization) => {
    return genericRequest(urlResourceCreate, "post", payload, authorization);
};