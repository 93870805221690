import { genericRequest } from "../generic";
import { urlAuthLogin, urlAuthRegister } from "../config";

/**
 *  # apiTasksLoadGroup
 *
 * @param {string} authorization - Jwt token.
 *
 * @returns {object}
 */
export const apiLogin = async (username, password) => {

    const payload = {
        "username": username,
        "password": password
    }

    return genericRequest(urlAuthLogin, "post", payload, "");
};


export const apiRegister = async (username, password, email) => {

    const payload = {
        "username": username,
        "password": password,
        "email": email,
    }

    return genericRequest(urlAuthRegister, "post", payload, "");
};
