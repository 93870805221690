// # reducers
// combines all reducers for the redux store

// import dependencies
import { combineReducers } from "redux";

// import reducers
import taskReducer         from "./tasks";
import groupReducer        from "./groups";
import usersReducer        from "./users";
import requirementsReducer from "./requirements";
import authReducer         from "./auth";
import resourcesReducer    from "./resources";

// set constants
const ENTRY_REDUCER = combineReducers({
    taskReducer,
    groupReducer,
    usersReducer,
    requirementsReducer,
    authReducer,
    resourcesReducer,
});

// export the reducer
export default ENTRY_REDUCER;