import React       from 'react';

class Homepage extends React.Component {

  state = {};

  render(){
    return (
      <div className="c-homepage">
        Homepage
      </div>
    )
  };
}

export default Homepage;